import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';

const Aspiration = () => {
  const title = 'Our aspiration';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>

      <p>
        To be happy in this world… it is necessary to feel oneself not merely an
        isolated individual whose day will soon be over, but part of the stream
        of life flowing on from the first germ to the remote and unknown future.
      </p>
      <p>
        Whatever my personal activities, I may be defeated by death, or by
        certain kinds of diseases; I may be overcome by my enemies; I may find
        that I have embarked upon an unwise course which cannot lead to success.
        In a thousand ways the failure of purely personal hopes may be
        unavoidable, but if personal aims have been part of larger hopes for
        humanity, there is not the same utter defeat when failure comes. The man
        who is working for some much-needed reform … may be forced to realise
        that what he has worked for will not come about in his lifetime. But he
        need not on that account sink into complete despair, provided that he is
        interested in the future of mankind apart from his own participation in
        it.
      </p>
      <strong class="indent">Bertrand Russell</strong>

      <hr />

      <p>
        要人生快樂，我們需要認識到自己不是日子將要走到盡頭的孤立的個體，而是附屬於生命的長河，從最先的單元一直流往那遙遠而未知的將來。
      </p>
      <p>
        無論我個人做着甚麼事，我隨時都可能被死亡或是某些疾病征服；也許我會被敵人挫敗；也許我發覺我選擇了一條愚蠢的路，永遠都不能成功。很多時候純粹個人的冀望都免不了受挫。但如果個人的目標是全人類偉大目標的一部份，失敗來臨時便不會極度灰心。如果你正致力於某些迫切及重大的改革，而又發現你有生之年都不會達成目標，你也不會因此而墮入絕望的深淵，只要你除了身體力行之外還着眼於人類的將來。
      </p>
      <strong class="indent">羅素</strong>
    </Layout>
  );
};

export default Aspiration;
